import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { MessageSquare, Users, Sun, Moon, LogOut } from 'lucide-react';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';

const UserAvatar = ({ userName, photoURL, theme, size = "w-8 h-8" }) => {
  const getInitials = (name) => {
    if (!name) return '??';
    if (name.includes('@')) {
      name = name.split('@')[0];
    }
    name = name.charAt(0).toUpperCase() + name.slice(1);
    return name
      .split(' ')
      .map(n => n[0])
      .join('')
      .toUpperCase()
      .slice(0, 2);
  };

  if (photoURL) {
    return (
      <img
        src={photoURL}
        alt={userName || 'User'}
        className={`${size} rounded-full object-cover border-2`}
        style={{
          borderColor: theme.colors.primary.main
        }}
      />
    );
  }

  const initials = getInitials(userName);

  return (
    <div 
      className={`${size} rounded-full border-2 flex items-center justify-center`}
      style={{
        backgroundColor: theme.colors.primary.light,
        borderColor: theme.colors.primary.main,
        color: theme.colors.primary.dark
      }}
    >
      <span className="text-sm font-medium">{initials}</span>
    </div>
  );
};

const Logo = ({ isFamily }) => (
  <svg viewBox="0 0 40 40" className="w-8 h-8">
    {isFamily ? (
      // Family chat logo - interconnected circles representing family
      <>
        <circle cx="20" cy="15" r="8" fill="currentColor" fillOpacity="0.8" />
        <circle cx="15" cy="25" r="8" fill="currentColor" fillOpacity="0.6" />
        <circle cx="25" cy="25" r="8" fill="currentColor" fillOpacity="0.7" />
      </>
    ) : (
      // Assistant chat logo - speech bubble with dots
      <>
        <path 
          d="M35 20c0 8.284-6.716 15-15 15-2.808 0-5.438-.77-7.686-2.115L5 35l2.115-7.314A14.938 14.938 0 015 20c0-8.284 6.716-15 15-15 8.284 0 15 6.716 15 15z" 
          fill="currentColor" 
          fillOpacity="0.8"
        />
        <circle cx="15" cy="20" r="2" fill="white" />
        <circle cx="20" cy="20" r="2" fill="white" />
        <circle cx="25" cy="20" r="2" fill="white" />
      </>
    )}
  </svg>
);

const TopNavigation = ({ theme, isDarkMode, setIsDarkMode, cycleTheme, user }) => {
  const [showUserMenu, setShowUserMenu] = useState(false);

  const navItems = [
    { id: 'assistant', label: 'Assistant', icon: MessageSquare, path: '/' },
    { id: 'family', label: 'Family', icon: Users, path: '/family' }
  ];

  return (
    <div className="sticky top-0 z-10 backdrop-blur-md bg-opacity-50 p-4">
      <div className="max-w-4xl mx-auto flex items-center justify-between">
        {/* Theme controls */}
        <div className="flex items-center gap-2">
          <motion.button
            onClick={cycleTheme}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="p-2 rounded-full"
            style={{
              backgroundColor: isDarkMode 
                ? `${theme.colors.primary.dark}80`
                : `${theme.colors.primary.light}40`,
            }}
          >
            <span className="text-xl">{theme.icon}</span>
          </motion.button>

          <motion.button
            onClick={() => setIsDarkMode(!isDarkMode)}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="p-2 rounded-full"
            style={{
              backgroundColor: isDarkMode 
                ? `${theme.colors.primary.dark}80`
                : `${theme.colors.primary.light}40`,
            }}
          >
            {isDarkMode ? <Moon className="w-5 h-5" /> : <Sun className="w-5 h-5" />}
          </motion.button>
        </div>

        {/* Navigation */}
        <div className="flex space-x-4">
          {navItems.map((item) => (
            <NavLink
              key={item.id}
              to={item.path}
              className={({ isActive }) => `
                relative px-4 py-2 rounded-full flex items-center space-x-2 transition-all
                ${isActive 
                  ? `text-${theme.colors.primary.main}` 
                  : isDarkMode 
                    ? 'text-gray-300 hover:text-gray-100' 
                    : 'text-gray-600 hover:text-gray-900'
                }
              `}
            >
              {({ isActive }) => (
                <>
                  <Logo isFamily={item.id === 'family'} />
                  <span className="hidden sm:inline">{item.label}</span>
                  {isActive && (
                    <motion.div
                      layoutId="activeIndicator"
                      className="absolute inset-0 rounded-full -z-10"
                      style={{
                        backgroundColor: theme.colors.primary.main + '20'
                      }}
                      transition={{ type: "spring", bounce: 0.2, duration: 0.6 }}
                    />
                  )}
                </>
              )}
            </NavLink>
          ))}
        </div>

        {/* User menu */}
        <div className="relative">
          <motion.button
            onClick={() => setShowUserMenu(!showUserMenu)}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="relative"
          >
            <UserAvatar 
              userName={user?.email} 
              photoURL={user?.photoURL}
              theme={theme}
            />
          </motion.button>

          <AnimatePresence>
            {showUserMenu && (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                className="absolute right-0 mt-2 py-2 w-48 rounded-lg shadow-lg"
                style={{
                  backgroundColor: isDarkMode 
                    ? theme.colors.message.dark 
                    : theme.colors.message.light
                }}
              >
                <motion.button
                  onClick={() => signOut(auth)}
                  whileHover={{ scale: 1.02 }}
                  className="w-full px-4 py-2 text-left flex items-center gap-2 hover:bg-black hover:bg-opacity-5"
                  style={{
                    color: theme.colors.text[isDarkMode ? 'dark' : 'light']
                  }}
                >
                  <LogOut className="w-4 h-4" />
                  <span>Logout</span>
                </motion.button>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default TopNavigation;