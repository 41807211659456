import React, { useState, useRef, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ref, query, limitToLast, onValue, push, update } from 'firebase/database';
import { auth, database } from './firebase';  // Add auth import here
import { useAuth } from './contexts/AuthContext';
import TopNavigation from './components/TopNavigation';
import NotificationService from './notificationService';


// Theme Definitions remain the same
const Themes = {
  clouds: {
    id: 'clouds',
    name: 'Clouds',
    icon: '☁️',
    background: {
      light: '/backgrounds/pink-clouds.jpg',
      dark: '/backgrounds/stars.jpg'
    },
    colors: {
      primary: {
        dark: '#9B4F96',
        main: '#D67BFF',
        light: '#FFB3FF',
      },
      accent: {
        main: '#F6E6FF',
        light: '#FFE5F1',
      },
      text: {
        light: {
          primary: 'rgba(45, 55, 72, 0.9)',
          secondary: 'rgba(45, 55, 72, 0.7)',
          inverse: 'rgba(255, 255, 255, 0.9)'
        },
        dark: {
          primary: 'rgba(255, 255, 255, 0.9)',
          secondary: 'rgba(255, 255, 255, 0.7)',
          inverse: 'rgba(45, 55, 72, 0.9)'
        }
      },
      message: {
        light: {
          background: 'rgba(255, 255, 255, 0.8)',
          text: 'rgba(45, 55, 72, 0.9)',
          userBackground: 'rgba(214, 123, 255, 0.9)'
        },
        dark: {
          background: 'rgba(255, 255, 255, 0.1)',
          text: 'rgba(255, 255, 255, 0.9)',
          userBackground: 'rgba(155, 79, 150, 0.9)'
        }
      }
    }
  },
  ocean: {
    id: 'ocean',
    name: 'Ocean',
    icon: '🌊',
    background: {
      light: '/backgrounds/underwater.jpg',
      dark: '/backgrounds/night-ocean.jpg'
    },
    colors: {
      primary: {
        dark: '#0C356A',
        main: '#0174BE',
        light: '#36ADFF',
      },
      accent: {
        main: '#FFC436',
        light: '#FFF0CE',
      },
      text: {
        light: {
          primary: 'rgba(44, 82, 130, 0.9)',
          secondary: 'rgba(44, 82, 130, 0.7)',
          inverse: 'rgba(235, 248, 255, 0.9)'
        },
        dark: {
          primary: 'rgba(235, 248, 255, 0.9)',
          secondary: 'rgba(235, 248, 255, 0.7)',
          inverse: 'rgba(44, 82, 130, 0.9)'
        }
      },
      message: {
        light: {
          background: 'rgba(235, 248, 255, 0.8)',
          text: 'rgba(44, 82, 130, 0.9)',
          userBackground: 'rgba(1, 116, 190, 0.9)'
        },
        dark: {
          background: 'rgba(26, 54, 93, 0.2)',
          text: 'rgba(235, 248, 255, 0.9)',
          userBackground: 'rgba(12, 53, 106, 0.9)'
        }
      }
    }
  },
  forest: {
    id: 'forest',
    name: 'Forest',
    icon: '🌿',
    background: {
      light: '/backgrounds/forest.jpg',
      dark: '/backgrounds/night-forest.jpg'
    },
    colors: {
      primary: {
        dark: '#1B4332',
        main: '#2D6A4F',
        light: '#95D5B2',
      },
      accent: {
        main: '#95D5B2',
        light: '#D8F3DC',
      },
      text: {
        light: {
          primary: 'rgba(39, 103, 73, 0.9)',
          secondary: 'rgba(39, 103, 73, 0.7)',
          inverse: 'rgba(240, 255, 244, 0.9)'
        },
        dark: {
          primary: 'rgba(240, 255, 244, 0.9)',
          secondary: 'rgba(240, 255, 244, 0.7)',
          inverse: 'rgba(39, 103, 73, 0.9)'
        }
      },
      message: {
        light: {
          background: 'rgba(240, 255, 244, 0.8)',
          text: 'rgba(39, 103, 73, 0.9)',
          userBackground: 'rgba(45, 106, 79, 0.9)'
        },
        dark: {
          background: 'rgba(39, 103, 73, 0.2)',
          text: 'rgba(240, 255, 244, 0.9)',
          userBackground: 'rgba(27, 67, 50, 0.9)'
        }
      }
    }
  }
};


const LoadingLogo = ({ theme }) => {
  const pulseVariant = {
    pulse: {
      scale: [1, 1.2, 1],
      opacity: [0.6, 0.8, 0.6],
      transition: {
        duration: 2,
        repeat: Infinity,
        ease: "easeInOut"
      }
    }
  };

  return (
    <motion.div
      animate={{ rotate: 360 }}
      transition={{
        duration: 8,
        repeat: Infinity,
        ease: "linear"
      }}
      className="w-16 h-16"
    >
      <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
        {[
          { cx: 28, cy: 20, r: 12, color: theme.colors.primary.dark },
          { cx: 36, cy: 20, r: 12, color: theme.colors.primary.main },
          { cx: 32, cy: 28, r: 8, color: theme.colors.primary.light }
        ].map((circle, index) => (
          <motion.circle
            key={index}
            cx={circle.cx}
            cy={circle.cy}
            r={circle.r}
            fill={circle.color}
            variants={pulseVariant}
            animate="pulse"
          />
        ))}
      </svg>
    </motion.div>
  );
};

const UserAvatar = ({ userName, photoURL, theme, size = "w-10 h-10" }) => {
  // Helper function to get initials either from full name or email
  const getInitials = (name) => {
    if (!name) return '??';
    
    // If it's an email address, use the part before @
    if (name.includes('@')) {
      name = name.split('@')[0];
    }
    
    // Capitalize first letter if it's from email
    name = name.charAt(0).toUpperCase() + name.slice(1);
    
    // If it's a full name, get initials from each word
    return name
      .split(' ')
      .map(n => n[0])
      .join('')
      .toUpperCase()
      .slice(0, 2);
  };

  if (photoURL) {
    return (
      <img
        src={photoURL}
        alt={userName || 'User'}
        className={`${size} rounded-full object-cover border-2`}
        style={{
          borderColor: theme.colors.primary.main
        }}
      />
    );
  }

  const initials = getInitials(userName);

  return (
    <div 
      className={`${size} rounded-full border-2 flex items-center justify-center`}
      style={{
        backgroundColor: theme.colors.primary.light,
        borderColor: theme.colors.primary.main,
        color: theme.colors.primary.dark
      }}
    >
      <span className="text-sm font-medium">{initials}</span>
    </div>
  );
};

const FamilyMessage = ({ message, theme, isDarkMode }) => {
  const isCurrentUser = message.userId === auth.currentUser?.uid;
  
  // Helper function to get display name
  const getDisplayName = (userName) => {
    if (!userName) return 'User';
    if (userName.includes('@')) {
      const name = userName.split('@')[0];
      return name.charAt(0).toUpperCase() + name.slice(1);
    }
    return userName;
  };

  const displayName = getDisplayName(message.userName);
  
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className={`flex items-end gap-3 mb-4 ${isCurrentUser ? 'flex-row-reverse' : 'flex-row'}`}
    >
      <motion.div
        whileHover={{ scale: 1.05 }}
        className="relative"
      >
        <UserAvatar 
          userName={message.userName} 
          photoURL={message.userPhoto}
          theme={theme}
        />
        <span
          className="absolute -bottom-1 text-xs font-medium px-2 rounded-full"
          style={{
            backgroundColor: theme.colors.primary.light,
            color: theme.colors.primary.dark,
            right: isCurrentUser ? 'auto' : '-1rem',
            left: isCurrentUser ? '-1rem' : 'auto',
          }}
        >
          {displayName}
        </span>
      </motion.div>

      <motion.div
        whileHover={{ scale: 1.01 }}
        className={`max-w-md p-3 rounded-2xl ${isCurrentUser ? 'rounded-br-sm' : 'rounded-bl-sm'}`}
        style={{
          backgroundColor: isCurrentUser 
            ? `${theme.colors.primary.main}CC`
            : isDarkMode 
              ? 'rgba(255, 255, 255, 0.1)'
              : 'rgba(255, 255, 255, 0.8)',
          color: isCurrentUser
            ? '#FFFFFF'
            : theme.colors.text[isDarkMode ? 'dark' : 'light'],
          boxShadow: `0 4px 12px ${theme.colors.primary.main}40`,
        }}
      >
        <p className="text-sm whitespace-pre-wrap">{message.content}</p>
        <span className="text-xs opacity-75 mt-1 block">
          {new Date(message.timestamp).toLocaleTimeString([], { 
            hour: '2-digit', 
            minute: '2-digit' 
          })}
        </span>
      </motion.div>
    </motion.div>
  );
};

const FamilyChat = ({ isDarkMode, setIsDarkMode }) => {
  const [currentTheme, setCurrentTheme] = useState(Themes.ocean);
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const { user } = useAuth();

  // Setup notifications with useCallback
  const setupNotifications = useCallback(async () => {
    if (!user) return;
    
    try {
      const permissionGranted = await NotificationService.requestPermission();
      if (!permissionGranted) {
        console.log('Notification permission denied');
        return;
      }

      await NotificationService.registerServiceWorker();
      await NotificationService.subscribeToPush(user.uid);
    } catch (error) {
      console.error('Error setting up notifications:', error);
    }
  }, [user]);

  // Handle messages and notifications
  useEffect(() => {
    if (!user) return;

    const chatRef = query(ref(database, 'familyChat'), limitToLast(50));
    const unsubscribe = onValue(chatRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const messageList = Object.entries(data)
          .map(([key, value]) => ({
            ...value,
            id: key,
          }))
          .sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
        
        setMessages(messageList);
        
        // Update badge count for unread messages
        const unreadCount = messageList.filter(msg => 
          msg.userId !== user.uid && 
          new Date(msg.timestamp) > new Date(localStorage.getItem('lastRead'))
        ).length;
        
        NotificationService.updateBadgeCount(unreadCount);
      }
    });

    // Set up notifications and update last read timestamp
    setupNotifications();
    localStorage.setItem('lastRead', new Date().toISOString());

    return () => unsubscribe();
  }, [user, setupNotifications]);

  // Auto-scroll to bottom when messages change
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  // Theme cycling
  const cycleTheme = useCallback(() => {
    const themeKeys = Object.keys(Themes);
    const currentIndex = themeKeys.indexOf(currentTheme.id);
    const nextIndex = (currentIndex + 1) % themeKeys.length;
    setCurrentTheme(Themes[themeKeys[nextIndex]]);
  }, [currentTheme.id]);

  // Message sending
  const handleSendMessage = async (e) => {
    e?.preventDefault();
    if (!inputText.trim() || !user || isLoading) return;
    
    setIsLoading(true);
    const messageText = inputText;
    setInputText('');

    const displayName = user.email 
      ? user.email.split('@')[0].charAt(0).toUpperCase() + user.email.split('@')[0].slice(1)
      : 'User';

    const newMessage = {
      content: messageText,
      userId: user.uid,
      userName: displayName,
      userPhoto: user.photoURL,
      timestamp: new Date().toISOString()
    };

    try {
      // Optimistic update
      setMessages(prev => [...prev, { ...newMessage, id: Date.now().toString() }]);
      
      // Firebase update
      const chatRef = ref(database, 'familyChat');
      const newMessageRef = push(chatRef);
      await update(newMessageRef, newMessage);

      // Update last read timestamp and clear badge
      localStorage.setItem('lastRead', new Date().toISOString());
      NotificationService.updateBadgeCount(0);
    } catch (error) {
      console.error('Error sending message:', error);
      // Rollback optimistic update if Firebase update fails
      setMessages(prev => prev.filter(msg => msg.id !== Date.now().toString()));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col">
      <TopNavigation 
        theme={currentTheme}
        isDarkMode={isDarkMode}
        setIsDarkMode={setIsDarkMode}
        cycleTheme={cycleTheme}
        user={user}
      />

      <div 
        className="flex-1 flex flex-col overflow-y-auto p-4"
        style={{
          backgroundImage: `url(${isDarkMode ? currentTheme.background.dark : currentTheme.background.light})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundAttachment: 'fixed'
        }}
      >
        <div className="max-w-4xl w-full mx-auto flex-1 flex flex-col justify-end">
          <div className="space-y-4">
            <AnimatePresence initial={false}>
              {messages.map((message, index) => (
                <FamilyMessage
                  key={message.id || index}
                  message={message}
                  theme={currentTheme}
                  isDarkMode={isDarkMode}
                />
              ))}
            </AnimatePresence>
          </div>
          {isLoading && (
            <div className="flex justify-center py-4">
              <LoadingLogo theme={currentTheme} />
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>
      </div>

      <div className="sticky bottom-0 p-4 backdrop-blur-md">
        <form 
          onSubmit={handleSendMessage}
          className="max-w-4xl mx-auto flex gap-2"
        >
          <input
            type="text"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleSendMessage(e);
              }
            }}
            placeholder="Share something with the family..."
            className="flex-1 p-3 rounded-full outline-none"
            style={{
              backgroundColor: isDarkMode
                ? 'rgba(255, 255, 255, 0.1)'
                : 'rgba(255, 255, 255, 0.8)',
              color: currentTheme.colors.text[isDarkMode ? 'dark' : 'light'],
            }}
          />
          <motion.button
            type="submit"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            disabled={isLoading || !inputText.trim()}
            className="px-6 py-3 rounded-full font-medium"
            style={{
              backgroundColor: currentTheme.colors.primary.main,
              color: '#FFFFFF',
              opacity: isLoading || !inputText.trim() ? 0.5 : 1
            }}
          >
            Send
          </motion.button>
        </form>
      </div>
    </div>
  );
};

export default FamilyChat;