import { ref, update, onValue, get } from 'firebase/database';
import { database } from './firebase';
import Favico from 'favico.js';

const VAPID_PUBLIC_KEY = 'BPJxDAATcB1AyF0ncVNARdhaOOYWNebs0MeEwK2wfAkIvGP9M17fA1PsHYAN60Uazykrr3GHckDwscJZ85FRM3k';

class NotificationService {
  static favicon = new Favico({
    animation: 'pop',
    bgColor: '#0C356A',
    textColor: '#FFFFFF'
  });

  static async requestPermission() {
    try {
      const permission = await Notification.requestPermission();
      return permission === 'granted';
    } catch (error) {
      console.error('Error requesting notification permission:', error);
      return false;
    }
  }

  static async registerServiceWorker() {
    try {
      if (!('serviceWorker' in navigator)) {
        throw new Error('Service Worker not supported');
      }

      const registration = await navigator.serviceWorker.register('/serviceWorker.js');
      
      if (registration.active) {
        return registration;
      }

      // Wait for the service worker to activate if it's not already
      return new Promise((resolve) => {
        registration.addEventListener('activate', () => resolve(registration));
      });
    } catch (error) {
      console.error('Error registering service worker:', error);
      throw error;
    }
  }

  static async subscribeToPush(userId) {
    try {
      const registration = await navigator.serviceWorker.ready;
      let subscription = await registration.pushManager.getSubscription();
      
      if (!subscription) {
        subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: this.urlBase64ToUint8Array(VAPID_PUBLIC_KEY)
        });
      }

      // Store subscription data
      const subscriptionData = {
        endpoint: subscription.endpoint,
        keys: {
          p256dh: btoa(String.fromCharCode.apply(null, 
            new Uint8Array(subscription.getKey('p256dh')))),
          auth: btoa(String.fromCharCode.apply(null, 
            new Uint8Array(subscription.getKey('auth'))))
        },
        timestamp: new Date().toISOString()
      };

      // Store subscription in Firebase
      const userSubscriptionRef = ref(database, `pushSubscriptions/${userId}`);
      await update(userSubscriptionRef, subscriptionData);

      return subscription;
    } catch (error) {
      console.error('Error subscribing to push notifications:', error);
      throw error;
    }
  }

  static async updateBadgeCount(count) {
    try {
      // Update favicon badge
      this.favicon.badge(count);
      
      // Update app badge for installed PWAs
      if ('setAppBadge' in navigator) {
        if (count > 0) {
          await navigator.setAppBadge(count);
        } else {
          await navigator.clearAppBadge();
        }
      }
    } catch (error) {
      console.error('Error updating badge:', error);
    }
  }

  static setupMessageListeners(userId) {
    if (!userId) return;

    const messagesRef = ref(database, 'familyChat');
    onValue(messagesRef, async (snapshot) => {
      const messages = snapshot.val();
      if (messages) {
        // Count unread messages
        const unreadCount = Object.values(messages).filter(msg => 
          msg.userId !== userId && !msg.readBy?.[userId]
        ).length;
        
        // Update badge counts
        await this.updateBadgeCount(unreadCount);

        // Show notification for new messages when app is not focused
        if (document.hidden) {
          const latestMessage = Object.values(messages)
            .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))[0];

          if (latestMessage && 
              latestMessage.userId !== userId && 
              !latestMessage.readBy?.[userId]) {
            await this.showNotification(latestMessage);
          }
        }
      }
    });
  }

  static async showNotification(message) {
    if (Notification.permission === 'granted') {
      try {
        const registration = await navigator.serviceWorker.ready;
        await registration.showNotification('New Family Message', {
          body: message.content,
          icon: '/logo192.png',
          badge: '/badge.png',
          tag: 'family-chat',
          renotify: true,
          vibrate: [200, 100, 200],
          data: { url: '/family' },
          actions: [
            {
              action: 'view',
              title: 'View'
            }
          ]
        });
      } catch (error) {
        // Fallback to regular notification if service worker fails
        new Notification('New Family Message', {
          body: message.content,
          icon: '/logo192.png'
        });
      }
    }
  }

  static async markMessagesAsRead(userId) {
    try {
      const messagesRef = ref(database, 'familyChat');
      const snapshot = await get(messagesRef);
      const messages = snapshot.val();
      
      if (messages) {
        const updates = {};
        Object.entries(messages).forEach(([key, message]) => {
          if (!message.readBy?.[userId]) {
            updates[`${key}/readBy/${userId}`] = true;
          }
        });
        
        if (Object.keys(updates).length > 0) {
          await update(messagesRef, updates);
        }
        
        await this.updateBadgeCount(0);
      }
    } catch (error) {
      console.error('Error marking messages as read:', error);
    }
  }

  static urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  static isPushSupported() {
    return 'serviceWorker' in navigator && 
           'PushManager' in window &&
           'Notification' in window;
  }

  static async unsubscribe(userId) {
    try {
      const registration = await navigator.serviceWorker.ready;
      const subscription = await registration.pushManager.getSubscription();
      
      if (subscription) {
        await subscription.unsubscribe();
      }
      
      // Remove subscription from Firebase
      const userSubscriptionRef = ref(database, `pushSubscriptions/${userId}`);
      await update(userSubscriptionRef, null);
      
      // Clear any existing badges
      await this.updateBadgeCount(0);
      
      return true;
    } catch (error) {
      console.error('Error unsubscribing from push notifications:', error);
      throw error;
    }
  }
}

export default NotificationService;