import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
    apiKey: "AIzaSyDi2C1D2x9yah5E-VFg023CP7LpNjcaR9Y",
    authDomain: "chat-app-b7301.firebaseapp.com",
    databaseURL: "https://chat-app-b7301-default-rtdb.firebaseio.com",
    projectId: "chat-app-b7301",
    storageBucket: "chat-app-b7301.appspot.com",
    messagingSenderId: "507382479061",
    appId: "1:507382479061:web:7cbccdf256d96b81c64a5c",
    measurementId: "G-4SLQKG0XLD"
  };
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const database = getDatabase(app);