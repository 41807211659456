// utils/parshaCalculator.js

// Johannesburg GeoName ID is 993800
const JOHANNESBURG_GEOID = 993800;

export const getJewishCalendarInfo = async () => {
  try {
    // Get current date and end of week for range
    const today = new Date();
    const endOfWeek = new Date(today);
    endOfWeek.setDate(today.getDate() + 7);

    // Format dates for API
    const startDate = today.toISOString().split('T')[0];
    const endDate = endOfWeek.toISOString().split('T')[0];

    // Construct API URL with specific parameters
    const url = new URL('https://www.hebcal.com/hebcal');
    const params = {
      v: '1',
      cfg: 'json',
      maj: 'on',      // Major holidays
      min: 'on',      // Minor holidays
      mod: 'on',      // Modern holidays
      s: 'on',        // Weekly parsha
      c: 'on',        // Candle lighting times
      geo: 'geoname',
      geonameid: JOHANNESBURG_GEOID,
      start: startDate,
      end: endDate,
      b: '18',        // Candle lighting 18 minutes before sunset
      M: 'on',        // Havdalah times
      leyning: 'off'  // Don't need Torah reading details
    };

    url.search = new URLSearchParams(params).toString();

    const response = await fetch(url.toString());
    const data = await response.json();

    // Filter and format the relevant information
    const relevantItems = data.items
      .filter(item => 
        item.category === 'parashat' || 
        (item.category === 'candles' && item.date.includes('Friday')) ||
        (item.category === 'havdalah' && item.date.includes('Saturday'))
      )
      .map(item => ({
        title: item.title,
        hebrew: item.hebrew,
        date: new Date(item.date),
        category: item.category
      }));

    return {
      items: relevantItems,
      location: data.location
    };
  } catch (error) {
    console.error('Error fetching Jewish calendar info:', error);
    return {
      items: [],
      location: null,
      error: 'Unable to load calendar information'
    };
  }
};

export const formatEventsForPrompt = (calendarInfo) => {
  if (!calendarInfo?.items?.length) {
    return 'Unable to load upcoming events';
  }

  try {
    const events = calendarInfo.items.map(item => {
      const dateStr = item.date.toLocaleDateString('en-ZA', {
        weekday: 'short',
        month: 'short',
        day: 'numeric'
      });

      if (item.category === 'candles') {
        const timeStr = item.date.toLocaleTimeString('en-ZA', {
          hour: '2-digit',
          minute: '2-digit'
        });
        return `Shabbat Candles ${dateStr} at ${timeStr}`;
      }

      if (item.category === 'parashat') {
        return `${item.title} (${item.hebrew})`;
      }

      if (item.category === 'havdalah') {
        const timeStr = item.date.toLocaleTimeString('en-ZA', {
          hour: '2-digit',
          minute: '2-digit'
        });
        return `Havdalah ${dateStr} at ${timeStr}`;
      }

      return item.title;
    });

    return events.join(', ');
  } catch (error) {
    console.error('Error formatting events:', error);
    return 'Unable to format upcoming events';
  }
};