import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Ear, Speaker, XCircle, Send } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import DOMPurify from 'dompurify';
import { ref, query, limitToLast, onValue, push, update } from 'firebase/database';
import { database, auth } from './firebase';
import { useAuth } from './contexts/AuthContext';
import TopNavigation from './components/TopNavigation';
import { getJewishCalendarInfo, formatEventsForPrompt } from './utils/parshaCalculator';


const Themes = {
  clouds: {
    id: 'clouds',
    name: 'Clouds',
    icon: '☁️',
    background: {
      light: '/backgrounds/pink-clouds.jpg',
      dark: '/backgrounds/stars.jpg'
    },
    colors: {
      primary: {
        dark: 'rgba(122, 50, 118, 0.95)',
        main: 'rgba(214, 123, 255, 0.95)',
        light: 'rgba(255, 179, 255, 0.95)',
      },
      accent: {
        main: 'rgba(246, 230, 255, 0.95)',
        light: 'rgba(255, 229, 241, 0.95)',
      },
      text: {
        light: 'rgba(45, 55, 72, 1)',         // Full opacity for text
        dark: 'rgba(255, 255, 255, 1)'        // Full opacity for text
      },
      message: {
        user: {
          background: 'rgba(214, 123, 255, 0.9)',  // Slightly transparent but still visible
          text: 'rgba(255, 255, 255, 1)'          // White text for user messages
        },
        assistant: {
          light: 'rgba(255, 255, 255, 0.9)',      // Light mode background
          dark: 'rgba(45, 55, 72, 0.9)'           // Dark mode background
        }
      }
    }
  },
  ocean: {
    id: 'ocean',
    name: 'Ocean',
    icon: '🌊',
    background: {
      light: '/backgrounds/underwater.jpg',
      dark: '/backgrounds/night-ocean.jpg'
    },
    colors: {
      primary: {
        dark: 'rgba(10, 40, 80, 0.95)',
        main: 'rgba(1, 116, 190, 0.95)',
        light: 'rgba(54, 173, 255, 0.95)',
      },
      accent: {
        main: 'rgba(255, 196, 54, 0.95)',
        light: 'rgba(255, 240, 206, 0.95)',
      },
      text: {
        light: 'rgba(10, 40, 80, 1)',
        dark: 'rgba(235, 248, 255, 1)'
      },
      message: {
        user: {
          background: 'rgba(1, 116, 190, 0.9)',
          text: 'rgba(255, 255, 255, 1)'
        },
        assistant: {
          light: 'rgba(255, 255, 255, 0.9)',
          dark: 'rgba(15, 40, 70, 0.9)'
        }
      }
    }
  },
  forest: {
    id: 'forest',
    name: 'Forest',
    icon: '🌿',
    background: {
      light: '/backgrounds/forest.jpg',
      dark: '/backgrounds/night-forest.jpg'
    },
    colors: {
      primary: {
        dark: 'rgba(21, 50, 38, 0.95)',
        main: 'rgba(45, 106, 79, 0.95)',
        light: 'rgba(149, 213, 178, 0.95)',
      },
      accent: {
        main: 'rgba(149, 213, 178, 0.95)',
        light: 'rgba(216, 243, 220, 0.95)',
      },
      text: {
        light: 'rgba(21, 50, 38, 1)',
        dark: 'rgba(240, 255, 244, 1)'
      },
      message: {
        user: {
          background: 'rgba(45, 106, 79, 0.9)',
          text: 'rgba(255, 255, 255, 1)'
        },
        assistant: {
          light: 'rgba(255, 255, 255, 0.9)',
          dark: 'rgba(21, 50, 38, 0.9)'
        }
      }
    }
  }
};

const LoadingLogo = ({ theme, isDarkMode }) => {
  const pulseVariant = {
    pulse: {
      scale: [1, 1.2, 1],
      opacity: [0.6, 0.8, 0.6],
      transition: {
        duration: 2,
        repeat: Infinity,
        ease: "easeInOut"
      }
    }
  };

  return (
    <motion.div
      animate={{ rotate: 360 }}
      transition={{
        duration: 8,
        repeat: Infinity,
        ease: "linear"
      }}
      className="w-16 h-16"
    >
      <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
        {[
          { cx: 28, cy: 20, r: 12, color: theme.colors.primary.dark },
          { cx: 36, cy: 20, r: 12, color: theme.colors.primary.main },
          { cx: 32, cy: 28, r: 8, color: theme.colors.primary.light }
        ].map((circle, index) => (
          <motion.circle
            key={index}
            cx={circle.cx}
            cy={circle.cy}
            r={circle.r}
            fill={circle.color}
            variants={pulseVariant}
            animate="pulse"
          />
        ))}
      </svg>
    </motion.div>
  );
};

const Message = React.memo(({ message, theme, isDarkMode, isSpeaking, speakMessage }) => {
  const isUser = message.role === 'user';
  const sanitizedContent = DOMPurify.sanitize(message.content);

  // Create a style object for consistent text colors
  const getTextStyles = () => {
    const textColor = isUser 
      ? 'rgba(255, 255, 255, 1)'  // White text for user messages
      : theme.colors.text[isDarkMode ? 'dark' : 'light'];

    return `
      .prose {
        color: ${textColor} !important;
      }
      .prose h1,
      .prose h2,
      .prose h3,
      .prose h4,
      .prose h5,
      .prose h6,
      .prose strong,
      .prose code,
      .prose a,
      .prose blockquote {
        color: ${textColor} !important;
      }
      .prose code,
      .prose pre {
        background-color: ${isUser 
          ? 'rgba(0, 0, 0, 0.2)' 
          : isDarkMode 
            ? 'rgba(0, 0, 0, 0.2)' 
            : 'rgba(0, 0, 0, 0.1)'
        } !important;
      }
      .prose pre code {
        background-color: transparent !important;
      }
      .prose blockquote {
        border-left-color: ${isUser 
          ? 'rgba(255, 255, 255, 0.4)' 
          : isDarkMode 
            ? 'rgba(255, 255, 255, 0.2)' 
            : 'rgba(0, 0, 0, 0.2)'
        } !important;
      }
    `;
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20, scale: 0.95 }}
      animate={{ opacity: 1, y: 0, scale: 1 }}
      transition={{ duration: 0.4 }}
      layout
      className={`mb-4 ${isUser ? 'ml-auto' : 'mr-auto'} max-w-[90%] flex items-end gap-2`}
    >
      <motion.div
        whileHover={{ scale: 1.01 }}
        whileTap={{ scale: 0.99 }}
        className={`rounded-2xl px-4 py-3 backdrop-blur-md shadow-lg ${
          isUser ? 'rounded-br-sm' : 'rounded-bl-sm'
        }`}
        style={{
          backgroundColor: isUser 
            ? theme.colors.primary.main
            : isDarkMode 
              ? 'rgba(255, 255, 255, 0.1)'
              : 'rgba(255, 255, 255, 0.8)',
        }}
      >
        <style>{getTextStyles()}</style>
        <div className="prose prose-sm max-w-none">
          <ReactMarkdown>{sanitizedContent}</ReactMarkdown>
        </div>
      </motion.div>

      <motion.button
        onClick={() => speakMessage(message.content)}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.95 }}
        className="p-2 rounded-full backdrop-blur-sm"
        style={{
          backgroundColor: isSpeaking 
            ? theme.colors.primary.main
            : 'transparent',
          color: isSpeaking
            ? 'rgba(255, 255, 255, 1)'
            : theme.colors.text[isDarkMode ? 'dark' : 'light'],
          border: '1px solid',
          borderColor: isSpeaking
            ? 'transparent'
            : isDarkMode
              ? 'rgba(255, 255, 255, 0.2)'
              : 'rgba(0, 0, 0, 0.1)',
        }}
      >
        {isSpeaking ? (
          <XCircle className="w-4 h-4" />
        ) : (
          <Speaker className="w-4 h-4" />
        )}
      </motion.button>
    </motion.div>
  );
});

const ChatInterface = ({ isDarkMode, setIsDarkMode }) => {
  const [currentTheme, setCurrentTheme] = useState(Themes.clouds);
  const [inputText, setInputText] = useState('');
  const [messages, setMessages] = useState([]);
  const [isListening, setIsListening] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedVoice, setSelectedVoice] = useState(null);

  const recognitionRef = useRef(null);
  const synthRef = useRef(window.speechSynthesis);
  const messagesEndRef = useRef(null);
  const { user } = useAuth();
  const [voiceSupported] = useState('webkitSpeechRecognition' in window || 'SpeechRecognition' in window);

  const cycleTheme = () => {
    const themeKeys = Object.keys(Themes);
    const currentIndex = themeKeys.indexOf(currentTheme.id);
    const nextIndex = (currentIndex + 1) % themeKeys.length;
    setCurrentTheme(Themes[themeKeys[nextIndex]]);
  };

  useEffect(() => {
    if (voiceSupported) {
      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      recognitionRef.current = new SpeechRecognition();
      recognitionRef.current.continuous = true;
      recognitionRef.current.interimResults = true;

      recognitionRef.current.onresult = (event) => {
        const transcript = Array.from(event.results)
          .map(result => result[0])
          .map(result => result.transcript)
          .join('');
        
        setInputText(transcript);
      };

      recognitionRef.current.onerror = () => {
        setIsListening(false);
      };
    }
  }, [voiceSupported]);

  useEffect(() => {
    if (user) {
      const chatRef = query(ref(database, `chatHistory/${user.uid}`), limitToLast(50));
      onValue(chatRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const messageList = Object.entries(data).map(([key, value]) => ({
            ...value,
            id: key,
          }));
          setMessages(messageList);
        }
      });
    }
  }, [user]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const toggleVoiceListening = () => {
    if (!isListening) {
      recognitionRef.current.start();
      setIsListening(true);
    } else {
      recognitionRef.current.stop();
      setIsListening(false);
    }
  };

  const speakMessage = (text) => {
    if (isSpeaking) {
      synthRef.current.cancel();
      setIsSpeaking(false);
      return;
    }

    const utterance = new SpeechSynthesisUtterance(text);
    if (selectedVoice) {
      utterance.voice = selectedVoice;
    }
    
    utterance.rate = 1.0;
    utterance.pitch = 1.0;
    utterance.volume = 1.0;
    
    utterance.onend = () => setIsSpeaking(false);
    utterance.onstart = () => setIsSpeaking(true);
    synthRef.current.speak(utterance);
  };

  const handleSendMessage = async () => {
    if (inputText.trim() === '' || !user || isLoading) return;
  
    setIsLoading(true);
    const messageText = inputText;
    setInputText('');
  
    // Create new user message
    const newMessage = {
      role: 'user',
      content: messageText,
      timestamp: new Date().toISOString()
    };
  
    // Update UI immediately
    setMessages(prev => [...prev, newMessage]);
  
    // Save to Firebase
    const chatRef = ref(database, `chatHistory/${user.uid}`);
    const newMessageRef = push(chatRef);
    await update(newMessageRef, newMessage);
  
    try {
      // Get current Jewish context using the REST API
      const calendarInfo = await getJewishCalendarInfo();
      const formattedEvents = formatEventsForPrompt(calendarInfo);
      const currentDate = new Date();
  
      // Create the system context message
      const systemContext = {
        role: 'system',
        content: `You are the Davidoff Family Assistant speaking with ${user.firstName} on ${currentDate.toLocaleDateString('en-US', { 
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        })} at ${currentDate.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: true
        })}.
  
  Jewish Calendar Context:
  ${formattedEvents}
  
  Family Context:
  - Brent: Father, works in youth employment at Harambee. Creator of GoFamily assistant, CoachMee , chatbot expert, needs to learn more Torah.
  - Vikki: Mother, doctor, practical & witty
  - Children: 
    • Grace (10): Thoughtful, playful, asks imaginative questions like favorite foods or hypotheticals
    • Isaiah (6): Curious explorer, switches between deep philosophical questions ("what's closest to infinity?"), factual knowledge (Titanic, sports stats), and passionate interests (especially soccer)
    • Rina (1.5): Joyful, learning to speak
  
  
  Interaction Style:
  - Dynamically adjusts depth and style based on the child and question:
    • For Isaiah: Can switch between brief sports facts, detailed historical explanations, or thoughtful explorations of big concepts
    • For Grace: Engages playfully with imaginative questions while offering thoughtful responses
  - Asks clarifying questions when needed ("Would you like a quick answer or should we explore this deeper?")
  - Makes complex topics accessible without oversimplifying
  - Maintains warmth and enthusiasm across all topic shifts
  - Encourages curiosity while guiding toward deeper understanding
  
  Core Capabilities:
  1. Deep Torah knowledge (תורה שבכתב, תורה שבעל פה, הלכה, חסידות)
  2. Chavruta-style learning approach
  3. Family-specific support and guidance
  4. Integration of Hebrew/Aramaic terms naturally
  5. Balance of deep insights with practical application`
      };

      const response = await fetch('https://api.ai21.com/studio/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer wxuFfL9sPF1ETd5Rcv4iF7CkxbEJFkIO`
        },
        body: JSON.stringify({
          model: 'jamba-1.5-large',
          messages: [
            systemContext,
            ...messages.map(m => ({ role: m.role, content: m.content })),
            { role: 'user', content: inputText }
          ],
          max_tokens: 2024,
          temperature: 0.4
        })
      });

      const data = await response.json();
      
      if (data.choices && data.choices[0]) {
        const assistantResponse = {
          role: 'assistant',
          content: data.choices[0].message.content,
          timestamp: new Date().toISOString()
        };
        
        setMessages(prev => [...prev, assistantResponse]);
        const responseRef = push(chatRef);
        await update(responseRef, assistantResponse);
      }
    } catch (error) {
      console.error('Error:', error);
      const errorMessage = {
        role: 'assistant',
        content: "I apologize, but I'm having trouble responding right now. Please try again.",
        timestamp: new Date().toISOString()
      };
      setMessages(prev => [...prev, errorMessage]);
      const errorRef = push(chatRef);
      await update(errorRef, errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen transition-colors duration-700 flex justify-center relative">
      <div 
        className="absolute inset-0 bg-cover bg-center -z-10"
        style={{
          backgroundImage: `url(${isDarkMode ? currentTheme.background.dark : currentTheme.background.light})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      
      <div className="w-full h-screen flex flex-col relative">
        <TopNavigation 
          theme={currentTheme}
          isDarkMode={isDarkMode}
          setIsDarkMode={setIsDarkMode}
          cycleTheme={cycleTheme}
          user={user}
        />

        <div className="w-full mx-auto px-4 flex flex-col h-full">
          {/* Messages */}
          <div className="flex-1 overflow-y-auto p-4">
            <div className="max-w-4xl mx-auto space-y-4">
              {messages.map(message => (
                <Message
                  key={message.id || message.timestamp}
                  message={message}
                  theme={currentTheme}
                  isDarkMode={isDarkMode}
                  isSpeaking={isSpeaking}
                  speakMessage={speakMessage}
                />
              ))}
              {isLoading && (
                <div className="flex justify-center">
                  <LoadingLogo theme={currentTheme} isDarkMode={isDarkMode} />
                </div>
              )}
              <div ref={messagesEndRef} />
            </div>
          </div>

          {/* Input Area */}
          <div className="sticky bottom-0 p-4 backdrop-blur-md">
            <div className="max-w-4xl mx-auto flex gap-2">
              <textarea
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                placeholder={isListening ? "Listening..." : "Type a message..."}
                disabled={isLoading}
                className="w-full p-3 rounded-lg outline-none resize-none"
                style={{
                  backgroundColor: isDarkMode
                    ? 'rgba(255, 255, 255, 0.1)'
                    : 'rgba(255, 255, 255, 0.8)',
                  color: currentTheme.colors.text[isDarkMode ? 'dark' : 'light'],
                  minHeight: '50px',
                  maxHeight: '150px'
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    handleSendMessage();
                  }
                }}
              />

              <div className="flex gap-2">
                {voiceSupported && (
                  <motion.button
                    onClick={toggleVoiceListening}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="p-3 rounded-lg"
                    style={{
                      backgroundColor: isListening 
                        ? currentTheme.colors.primary.main 
                        : isDarkMode
                          ? 'rgba(255, 255, 255, 0.1)'
                          : 'rgba(255, 255, 255, 0.8)',
                      color: isListening
                        ? '#FFFFFF'
                        : currentTheme.colors.text[isDarkMode ? 'dark' : 'light']
                    }}
                  >
                    <Ear className="w-5 h-5" />
                  </motion.button>
                )}

                <motion.button
                  onClick={handleSendMessage}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  disabled={isLoading || !inputText.trim()}
                  className="p-3 rounded-lg"
                  style={{
                    backgroundColor: currentTheme.colors.primary.main,
                    color: '#FFFFFF',
                    opacity: isLoading || !inputText.trim() ? 0.5 : 1
                  }}
                >
                  <Send className="w-5 h-5" />
                </motion.button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatInterface;